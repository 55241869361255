import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Stations from "../../Common/Stations";
import StationsUS from "../../Common/StationsUS";
// import { SliderText } from "./SliderText";
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';
// import Laptop from "../../Assets/images/laptop_agm-design-tool.png";
// import youtubeLogo from "../../Assets/images/youtube-icon.png";
// import LaptopMobile from "../../Assets/images/laptop_agm-design-tool_mobile.png";
// import blueCheckmark from "../../Assets/images/checkmark-blue.png";
// import whiteLarge from "../../Assets/images/banner-white-background-blob.png";
// import ModalVideo from 'react-modal-video'
import VideoModal from "../../Common/videoModal";
// import ReactPlayer from "react-player";
// import { render } from "@testing-library/react";
import withFormattedText from '../../Common/withFormattedText';
// import photograph from './../../Assets/images/po.21716763.png'
import baneerOffer from './../../Assets/images/bathsspecoffer.gif';
import baneerOfferSunroom from './../../Assets/images/bathsspecofferSun.gif';
import CloseIcon from '@mui/icons-material/Close';

import filesFetcher from '../../Services/filesFetcher';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getOfferData = async (setOffer) => {
  const project = process.env.REACT_APP_NAME;
  const offers = await filesFetcher.fetchPromoOffer();
  const offerData = offers.find((item) => item.value === capitalizeFirstLetter(project));

  if (offerData)
    setOffer(offerData.banner);
};

const Banner = () => {
  const [offer, setOffer] = useState(null);
  const project = process.env.REACT_APP_NAME;
  if (!offer) {
    getOfferData(setOffer);
    return null;
  }

  return (
    <div className={project === 'sunroom' ? "promoStamp" : 'promoStamp paint'}>

    <button onClick={(e)=> { 
      e.currentTarget.parentElement.style.display = 'none';

    }} className="closeBtn">
      <CloseIcon/>
    </button>
    <p className="topPar">
      <span>
        Save {offer.amount} on {capitalizeFirstLetter(project)} {project === 'sunroom' ? 'Renovations' : 'Services'}
      </span>
    </p>

    <p className="middleOffer desk">
    <strong>No</strong> Money Down <br/>
    <strong>No</strong> Interest <br/>
    <strong>No</strong> Payments for 1 Year*
    </p>
    <p className="middleOffer mobile">
    <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
    </p>

          { offer.extra ?
            <p style={{ textAlign: 'center', paddingTop: '0' }} className="middleOffer image desk">
            <strong>Take an extra <br/>{offer.extra} OFF your project</strong>
            </p> : null }

            { offer.extra ?
            <p style={{ textAlign: 'center', paddingTop: '0' }} className="middleOffer image mobile">
            <strong>Take an extra {offer.extra} OFF your project</strong>
            </p> : null }

      <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
      <div style={{ color: '#fff', fontWeight: 700 }} className="button">
        See Offer
      </div>
      </Link>


      <span className="endPart">Offer ends {offer.end}</span>
    </div>
  );
};

const DEFAULT_LIST_ITEMS = [
  "Deal with contractors",
  "Invite strangers to your home",
  "Drive to multiple showrooms",
  "And no more wasted time!",
]

const videos = {
  laptopVideo: 's0hz5E1E2ZU',
  HGTV: '5kVBooRtR4Q',
};

const handler = { isShowPromo: false };

class _TopSection extends Component {
  
  constructor (props) {
    super()
    this.state = {
      videoId: videos.laptopVideo,
      isOpen: false,
      countryCode: props.location?.countryCode,
    }
    this.openModal = this.openModal.bind(this)
    this.handleHGTVStar = this.handleHGTVStar.bind(this)
    this.Stantions = null
  }

  componentDidMount() {
    const handleScroll = (e) => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const promoRef = document.getElementById('promo2Elem');
   
      if (promoRef) {
        if (winScroll >= 70) {
          if (!handler.isShowPromo) {
            promoRef.classList.add('visible');
            handler.isShowPromo = true;
          }
        } else {
          if (handler.isShowPromo) {
            promoRef.classList.remove('visible');
            handler.isShowPromo = false;
          }
        }
      }
    };
    document.addEventListener("scroll", handleScroll);
  }

  componentDidUpdate() {
    const countryCode = this.props.location?.countryCode;
    if (countryCode && this.state.countryCode !== countryCode) this.setState({ countryCode });



  }

  openModal () {
    this.setState({ isOpen: true, videoId: videos.laptopVideo })
  }

  handleHGTVStar () {
    this.setState({ isOpen: true, videoId: videos.HGTV })
  }


  

  render(){
    let Renovation_Type__c = process.env.REACT_APP_NAME;
    const { 
      title = 'Try Our 3D Creator For FREE!', 
      subtitle = <><span>Select your finishes and get a quote in minutes from the comfort of your own home.</span>It’s so easy!</>, 
      listTitle = "NO NEED TO", listItems = DEFAULT_LIST_ITEMS, backgroundImage, className, children, isKitchenApp, appName 
    } = this.props;
 
        return (
          <>
          <Helmet>
            <title>{process.env.REACT_APP_TITLE}</title>
            <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
            <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
            {process.env.REACT_APP_NAME !== 'sunroom' ? <meta name="robots" content="index"/> : null}
          </Helmet>
            <section
              className={`home-section modified ${className ? className : ''}${appName === 'sunroom' ? appName : ''}`} 
              style={{ backgroundImage: `url(${backgroundImage})` }}
            >
              <div className="banner-cover" />
              <section className="home-banner">
                <div className="welcome-text">

                  {appName === 'painting' ? (
                    <>
                    <h1 className="title_desktop">{title}</h1>
                    <h1 className="title_mobile">Interior Painting</h1>
                    </>
                  ) : (
                    <h1 className={appName === 'sunroom' ? 'sunroonTitle' : ''}>{title}</h1>
                  )}
   
                  <p className="subtitle_desktop">{subtitle}</p>
                  <p className="subtitle_mobile">Done Right!</p>

                  {/* <h4>{listTitle}</h4>
                  <ul>
                    {listItems && listItems.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul> */}
                  <Link to="/get-started" className="blue-btn desktop-btn">Get Free Quote</Link>
                </div>
                { this.state.isOpen && 
                    <VideoModal
                      isOpen={this.state.isOpen}
                      videoId={this.state.videoId}
                      handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                    />
                }
                {/* <div className="footerInfoBar">
                  <div className="itemInfo">
                    <b className="border">Ivan A.</b>
                    <span className="border">CEO AGM Renovations</span>
                  </div>
                  <div onClick={this.handleHGTVStar} className="itemInfo hovered" style={{ margin: '0 30px' }}>
                    <b>Mike Holmes</b>
                    <span  style={{ padding: '0' }}><strong>HGTV</strong> Star & Contractor</span>
                    <hr/>
                  </div>
                </div> */}
              </section>
              {children}
              
            {/* <Link to="/get-started">
              <img className="promo2" id="promo2Elem" src={appName === 'sunroom' ? baneerOfferSunroom : baneerOffer}/>
            </Link> */}
              
            </section>
            <div className="mobile-visible mobile-visible--no-tablet">
              <Link to="/get-started" className="blue-btn">
                Get Free Quote
                </Link>
            </div>
            {/* {this.Stantions && <this.Stantions />} */}


              <Banner/>


            {this.state.countryCode === 'CA' 
              ? (<Stations/>)
              : (< StationsUS/>)
            }
          </>
        )

  }
}

export default _TopSection;
export const TopSection =  withFormattedText(_TopSection, ['subtitle']);
